export default () => {
    let images = document.querySelectorAll('img[srcset*="responsive-images"][sizes="1px"]');

    window.requestAnimationFrame(function () {
        for (let i = 0; i < images.length; i++) {
            let size = images[i].getBoundingClientRect().width;
            let sizes = Math.ceil(size / window.innerWidth * 100) + 'vw';
            images[i].sizes = sizes;
        }
    });
}